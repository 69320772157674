.App {
  text-align: center;
  background-color: #1a222c;
  height: auto;
  min-height: 100vh;
}

.header {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  color: white;
}

.service-status {
  display: block;
  font-size: 76px;
  font-weight: bold;
  line-height: 1;
}

.service-name {
  display: block;
  font-size: 32px;
  line-height: 1;
  font-weight: bold;
  margin-top: 12px;
}

.discord-link {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.copy-right {
  display: flex;
  position: absolute;
  justify-content: center;
  transform: translate(-50%, -50%);
  left: 50%;
  bottom: 0;
  color: white;
  font-size: 12px;
  gap: 8px;
}

.copy-right-logo {
  width: 32px;
  height: 32px;
}

.copy-right-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
